import {
  Box,
  Fade,
  ButtonBase,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { strings } from "../../services/translation";
import { useState, useEffect } from "react";
import { IS_BETA } from "../../constants/ignore";
import { styles } from "./styles";
//ICONS
import { ReactComponent as IconPlayStore } from "../../assets/icons/icon_play_store.svg";
import { ReactComponent as IconAppStore } from "../../assets/icons/icon_app_store.svg";
import { ReactComponent as IconLogo } from "../../assets/icons/icon_logo_white.svg";
import { ReactComponent as IconDiscord } from "../../assets/icons/icon_discord.svg";

const Intro = () => {
  const [beta, setBeta] = useState(IS_BETA ? IS_BETA : false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [isFirstImage, setIsFirstImage] = useState(true);

  const matchesHeight = useMediaQuery("(min-height:875px)");
  const matchesWidth = useMediaQuery("(min-width:800px)");

  useEffect(() => {
    const interval = setInterval(
      () => {
        setIsFirstImage(false);
        setFade(false);
      },
      isFirstImage || currentImageIndex === 0 ? 1000 : 7000
    );

    return () => {
      clearInterval(interval);
    };
  }, [currentImageIndex, isFirstImage]);

  useEffect(() => {
    if (!fade) {
      const timeout = setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 400);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [fade]);

  const currentImage = images[currentImageIndex];

  const goToGame = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      try {
        var timeout = setTimeout(function () {
          // Si l'ouverture de l'application prend trop de temps, redirigez vers le magasin d'applications
          alert(strings.messages.appNotInstalled);
        }, 1000);

        window.location.href = strings.urls.app;
      } catch (err) {
        alert(strings.messages.appNotInstalled);
      }
      //window.location.href = beta ? strings.urls.beta : strings.urls.play;
    } else {
      window.location.href = beta ? strings.urls.beta : strings.urls.play;
    }
  };

  const openUrl = (url: string) => {
    window.open(url, "_blank")?.focus();
  };

  return (
    <Box sx={styles.fullPage}>
      <Fade in={fade} timeout={{ appear: 0, enter: 1000, exit: 400 }}>
        <Box
          sx={[
            styles.bgImage,
            {
              background: ` linear-gradient(180deg, rgba(23, 24, 28, 0) 0%, #17181C 100%), center / cover no-repeat url(${currentImage})`,
            },
          ]}
        />
      </Fade>
      <ButtonBase
        sx={[styles.discordBtn, !matchesWidth && { padding: "6.8px 12px" }]}
        onClick={() => openUrl(strings.urls.discord)}
      >
        <Typography
          component={"span"}
          sx={[styles.discordBtnText, !matchesWidth && { fontSize: "12px" }]}
        >
          {strings.actions.joinUs}
        </Typography>
        <IconDiscord width={24} height={24} />
      </ButtonBase>
      <Box sx={styles.container}>
        <Box sx={styles.logo}>
          <IconLogo
            width={"min(10.2vh, 100px)"}
            height={"min(10.2vh, 100px)"}
          />
        </Box>
        <Typography component={"h1"} sx={styles.header}>
          {strings.labels.theNew}
          <Typography sx={styles.header}>
            {strings.labels.rolePlayingGames}
          </Typography>
        </Typography>
        <ButtonBase sx={styles.ctaBtn} onClick={goToGame}>
          <Typography sx={styles.ctaBtnText}>
            {strings.actions.playNow}
          </Typography>
          {beta ? (
            <Typography component={"span"} sx={styles.beta}>
              {"(beta)"}
            </Typography>
          ) : null}
        </ButtonBase>
        <Box
          sx={[
            styles.stores,
            !matchesHeight
              ? { top: "10.3vh", marginBottom: "16px" }
              : { top: "20.3vh" },
          ]}
        >
          <Typography sx={styles.storesText}>
            {strings.messages.availableOn}
          </Typography>
          <Box sx={[styles.storeBtns, !matchesWidth && { columnGap: "7.5px" }]}>
            <ButtonBase
              sx={styles.storeBtn}
              onClick={() => openUrl(strings.urls.google)}
            >
              <IconPlayStore
                width={"max(17vh, 97.8px)"}
                height={"max(5.04vh, 29px)"}
              />
            </ButtonBase>
            <ButtonBase
              sx={styles.storeBtn}
              onClick={() => openUrl(strings.urls.apple)}
            >
              <IconAppStore
                width={"max(17vh, 97.8px)"}
                height={"max(5.04vh, 29px)"}
              />
            </ButtonBase>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const images = [
  require("../../assets/images/placeHolder.jpg"),
  require("../../assets/images/BG1.jpg"),
  require("../../assets/images/BG2.jpg"),
  require("../../assets/images/BG3.jpg"),
  require("../../assets/images/BG4.jpg"),
  require("../../assets/images/BG5.jpg"),
  require("../../assets/images/BG6.jpg"),
  require("../../assets/images/BG7.jpg"),
  require("../../assets/images/BG8.jpg"),
  require("../../assets/images/BG9.jpg"),
  require("../../assets/images/BG10.jpg"),
  require("../../assets/images/BG11.jpg"),
  require("../../assets/images/BG12.jpg"),
  require("../../assets/images/BG13.jpg"),
  require("../../assets/images/BG14.jpg"),
  require("../../assets/images/BG15.jpg"),
  require("../../assets/images/BG17.jpg"),
  require("../../assets/images/BG19.jpg"),
  require("../../assets/images/BG20.jpg"),
  require("../../assets/images/BG21.jpg"),
  require("../../assets/images/BG22.jpg"),
  require("../../assets/images/BG24.jpg"),
]; // Liste des images de fond

export default Intro;
