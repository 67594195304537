import { Colors } from "../../style";
import {
  columnStartStyles,
  rowCenterStyles,
  columnCenterStyles,
  columnEndStyles,
} from "../../style/flex";
import { Gilroy, Eczar } from "../../style/fonts";

export const styles = {
  fullPage: {
    width: "100%",
    position: "relative",
    ...columnStartStyles,
    height: "100vh",
  },
  bgImage: {
    width: "100%",
    height: "85%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundPosition: "center",
    backgroundSize: "cover",
    //transition: "opacity 1s",
  },
  discordBtn: {
    position: "absolute",
    top: "24px",
    right: "24px",
    ...rowCenterStyles,
    columnGap: "10px",
    padding: "9px 16px",
    borderRadius: "8px",
    backgroundColor: Colors.PRIMARY,
    zIndex: 10,
  },
  discordBtnText: {
    color: Colors.WHITE,
    fontFamily: Gilroy,
    fontSize: "14px",
    lineHeight: "128%",
  },
  container: {
    ...columnCenterStyles,
    flex: 1,
    zIndex: 2,
  },
  logo: {
    marginBottom: "calc(10px + 3.4vh)",
  },
  header: {
    ...columnCenterStyles,
    fontFamily: Eczar.bold,
    lineHeight: "110%",
    fontSize: "min(calc(22px + 6vh), calc(32px + 4vw))",
    color: Colors.WHITE,
    textAlign: "center",
  },
  ctaBtn: {
    ...rowCenterStyles,
    background: `linear-gradient(270deg, ${Colors.HEART} 0%, ${Colors.ACCENT} 100%)`,
    borderRadius: "13px",
    padding: "calc(10px + 1.1vh) calc(10px + 3.5vh)",
    marginTop: "calc(10px + 3.4vh)",
  },
  ctaBtnText: {
    color: Colors.WHITE,
    fontFamily: Gilroy,
    lineHeight: "120%",
    fontSize: "calc(14px + 1vh)",
  },
  beta: {
    color: Colors.WHITE,
    fontFamily: Gilroy,
    fontSize: "calc(10px + 0.5vh)",
    paddingLeft: "7px",
    alignSelf: "center",
  },
  stores: {
    position: "relative",
    top: "14.3vh",
    ...columnEndStyles,
  },
  storesText: {
    color: Colors.OLD_PRICE,
    fontFamily: Gilroy,
    lineHeight: "120%",
    fontSize: "calc(10px + 0.8vh)",
  },
  storeBtns: {
    ...rowCenterStyles,
    columnGap: "14px",
  },
  storeBtn: {
    opacity: 0.7,
    marginTop: "calc(14px + 1vh)",
  },
};
