export const PRIMARY = "#17181C";
export const SECONDARY = "#7970FF";
export const ACCENT = "#7970FF";
export const BTN_ACTIVE = "#25262F";
export const BTN_INACTIVE = "#9897A3";
export const SECONDARY_TEXT = "#9897A3";
export const GRAY_TEXT = "#807E8F";
export const HEART = "#CD47F4";
export const LINK = "#4F5267";

export const PRIMARY_BRAQUAGE = "#131A1B";
export const SECONDARY_BRAQUAGE = "#263437";
export const ACCENT_BRAQUAGE = "#FEC85E"; // accentuated text

export const BUTTON_BRAQUAGE = "#263437";
export const BACKGROUND_BRAQUAGE = "#131A1B";
export const WHITE = "#E7E7E7";
export const BLACK = "#000000";
export const BACKGROUND_TOP_MENU = "#1E172F";
export const LIGHT_BACKGROUND_TOP_MENU = "#372B57";

export const LIBRARY_ICON = "#E95050";
export const SHOP_ICON = "#FFFFFF";
export const MENU_ICON = "#CD47F4";
export const MENU_INACTIVE = "#535467";
export const TICKET = "#FFD600";
export const EPISODES = "#BD47F4";
export const SUBTITLE = "#B2B2B2";
export const INPUT = "#3C3E4B";
export const LOCK = "#686A83";
export const PROGRESSFOND = "#343260";
export const PACK = "#47D8F7";

export const vertALEXTESGRANDSMORTS = "#50E98D";
export const ERROR = "#E95050";
export const PROMO = "#E95050";
export const OLD_PRICE = "#7B7E9E";
export const MENU_TEXT = "#A7A6B2";
export const PIRATE_GREEN = "#74C85D";
export const CARROUSEL_TEXT = "#A7A6B3";
export const VERT_FOND_LETTRE = "#31B88F";
export const JAUNE_FOND_LETTRE = "#C69D35";
export const ROUGE_FOND_LETTRE = "#9E5B73";
export const ORANGE_FOND_LETTRE = "#CE5858";
export const BLEU_FOND_LETTRE = "#1E3F67";
export const FOND_ITEM_POPUP = "#505267";
export const TOKEN = "#C8D7FF";

export const FACEBOOK = "#1877F2";

export const LETTERS = [
  ACCENT,
  HEART,
  VERT_FOND_LETTRE,
  JAUNE_FOND_LETTRE,
  ROUGE_FOND_LETTRE,
  BLEU_FOND_LETTRE,
  ORANGE_FOND_LETTRE,
];
