import { Box, Typography, useMediaQuery } from "@mui/material";
import { strings } from "../../services/translation";
import { columnCenterStyles, rowCenterStyles } from "../../style/flex";
import { Colors } from "../../style";
import { Gilroy } from "../../style/fonts";

const Logos = () => {
  const matchesWidth = useMediaQuery("(min-width:800px)");

  return (
    <Box sx={{ flex: 1, ...columnCenterStyles }}>
      <Typography sx={[styles.storesText, { marginBottom: "15px" }]}>
        {strings.labels.supportedBy}
      </Typography>
      <Box
        sx={[
          styles.footer,
          matchesWidth
            ? {
                ...rowCenterStyles,
                padding: "20px 20px",
                gap: "20px",
              }
            : { ...styles.footerColumn, gap: "20px" },
        ]}
      >
        <Box>
          <img
            src={require("../../assets/images/CNC-Logo.png")}
            alt="CNC"
            style={{ width: "160px", height: "34.5px" }}
          />
        </Box>
        <Box>
          <img
            src={require("../../assets/images/logo-region.png")}
            alt="Région Auvergne Rhône-Alpes"
            style={{ width: "149px", height: "34.5px" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  storesText: {
    color: Colors.OLD_PRICE,
    fontFamily: Gilroy,
    lineHeight: "120%",
    fontSize: "calc(10px + 0.8vh)",
  },
  footer: {
    flex: 1,
  },
  footerColumn: {
    ...columnCenterStyles,
  },
};

export default Logos;
