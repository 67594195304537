import React, { useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import { Colors } from "../../style";
import { Gilroy } from "../../style/fonts";
import { strings } from "../../services/translation";
import { Article } from "./Article";
import ArticleData from "../../models/ArticleData";

const FAQ = () => {
  const data: ArticleData[] = strings.faq.articles;

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container id="faq" sx={styles.container}>
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={styles.header}
      >
        {strings.faq.title}
      </Typography>
      <Box sx={{ width: "100%" }}>
        {data.map((article: ArticleData, index: number) => (
          <Article
            key={article.id}
            article={article}
            expanded={expanded}
            handleChange={handleChange}
            index={index}
            length={data.length}
          />
        ))}
      </Box>
    </Container>
  );
};

const styles = {
  container: {
    pt: { xs: 4, sm: 12 },
    pb: { xs: 8, sm: 16 },
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: { xs: 3, sm: 6 },
  },
  header: {
    color: Colors.WHITE,
    fontFamily: Gilroy,
    width: { sm: "100%", md: "60%" },
    textAlign: { sm: "left", md: "center" },
  },
};

export default FAQ;
