import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import InstaIcon from "@mui/icons-material/Instagram";

import {
  Box,
  Container,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { strings } from "../../services/translation";
import { Colors } from "../../style";
import { Gilroy } from "../../style/fonts";

const logoStyle = {
  width: "100px",
  height: "auto",
};

function Copyright() {
  return (
    <Typography style={styles.footerLink} mt={1}>
      {strings.labels.copyright}
      Tales Up&nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

const Footer = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <img
            src={require("../../assets/images/logo_white.png")}
            style={logoStyle}
            alt="logo of sitemark"
          />
        </Box>

        <Box
          sx={{
            display: { xs: "flex", sm: "flex" },
            marginTop: { xs: "20px", sm: 0 },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography style={styles.footerTitle} fontWeight={600}>
            {strings.labels.company}
          </Typography>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              flexDirection: { xs: "row", sm: "column" },
              flexWrap: { xs: "wrap", sm: "nowrap" },
              gap: 1,
              columnGap: { xs: 3, sm: 1 },
            }}
          >
            <Link style={styles.footerLink} href={strings.urls.periple}>
              {strings.labels.aboutUs}
            </Link>
            <Link style={styles.footerLink} href={strings.urls.socials}>
              {strings.labels.socials}
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "flex" },
            marginTop: { xs: "20px", sm: 0 },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography style={styles.footerTitle} fontWeight={600}>
            {strings.labels.legal}
          </Typography>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex" },
              flexDirection: { xs: "row", sm: "column" },
              flexWrap: { xs: "wrap", sm: "nowrap" },
              gap: 1,
              columnGap: { xs: 3, sm: 1 },
            }}
          >
            <Link style={styles.footerLink} href={strings.urls.legal}>
              {strings.labels.legal}
            </Link>
            <Link style={styles.footerLink} href={strings.urls.cguv}>
              {strings.labels.terms}
            </Link>
            <Link style={styles.footerLink} href={strings.urls.privacy}>
              {strings.labels.privacy}
            </Link>
            <Link style={styles.footerLink} href={strings.urls.contactUs}>
              {strings.labels.contact}
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: Colors.BTN_ACTIVE,
        }}
      >
        <div>
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.instagram.com/tales.up/"
            aria-label="Instagram"
            sx={{ alignSelf: "center", color: Colors.LINK }}
          >
            <InstaIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/tales_up"
            aria-label="X"
            sx={{ alignSelf: "center", color: Colors.LINK }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/76361196/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center", color: Colors.LINK }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
};

const styles = {
  footerTitle: {
    color: Colors.WHITE,
    fontSize: "18px",
    fontFamily: Gilroy,
  },
  footerLink: {
    color: Colors.LINK,
    fontSize: "16px",
    fontFamily: Gilroy,
    cursor: "pointer",
  },
};
export default Footer;
