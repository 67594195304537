/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import { Box, Fade, useMediaQuery } from "@mui/material";
import { changeLanguage, getLanguage, strings } from "./services/translation";
import { Gilroy } from "./style/fonts";

import { Colors } from "./style";
import Footer from "./components/Footer";
import FAQ from "./components/FAQ";
import Carousel from "./components/Carousel";
import Intro from "./components/Intro";
import Logos from "./components/Logos";

function App() {
  const [, updateState] = useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  // Media queries
  const matchesWidth = useMediaQuery("(min-width:800px)");
  const matchesL = useMediaQuery(`(min-width:${1200}px)`);
  const matchesMD = useMediaQuery(`(min-width:${800}px)`);
  const matchesSM = useMediaQuery(`(min-width:${600}px)`);

  /**
   *
   * LANGAGE
   *
   */

  useEffect(() => {
    checkLanguage();
  }, []);

  const checkLanguage = async () => {
    const value = localStorage.getItem("language");
    if ((value === "fr" || value === "en") && value !== getLanguage()) {
      changeLanguage(value);
    } else if (!value) {
      const userLang = strings.getInterfaceLanguage() || navigator.language;
      if (userLang) {
        if (
          userLang.includes("fr") ||
          (userLang.includes("FR") && getLanguage() !== "fr")
        ) {
          changeLanguage("fr");
        } else if (getLanguage() !== "en") {
          changeLanguage("en");
        }
      }
    }
    forceUpdate();
  };

  /**
   *
   * SIZING
   *
   */

  useEffect(() => {
    window.addEventListener("resize", () => handleResize());
    return () => {
      window.removeEventListener("resize", () => handleResize());
    };
  }, []);

  const calcultateCardWidth = () => {
    return matchesL
      ? screenWidth * 0.6 > 1200
        ? 1200 - 48
        : screenWidth * 0.6
      : matchesMD
      ? screenWidth * 0.7
      : matchesSM
      ? screenWidth - 48
      : screenWidth - 32;
  };

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  return (
    <Fade in={true} appear timeout={1000}>
      <Box sx={[styles.main]}>
        <Intro />
        <Carousel cardWidth={calcultateCardWidth()} />
        <FAQ />
        <Logos />
        <Footer />
      </Box>
    </Fade>
  );
}

const styles = {
  main: {
    width: "100%",
    height: "100%",
    position: "relative",
    //...columnStartStyles,
    overflowY: "scroll",
    backgroundColor: Colors.PRIMARY,
    "::-webkit-scrollbar": {
      width: "5px",
    },
    "::-webkit-scrollbar-track": {
      background: Colors.BTN_ACTIVE,
      borderRadius: "5px",
    },

    "::-webkit-scrollbar-thumb": {
      background: Colors.LINK,
      borderRadius: "5px",
    },

    "::-webkit-scrollbar-thumb:hover": {
      background: Colors.BTN_INACTIVE,
    },
  },
  storesText: {
    color: Colors.OLD_PRICE,
    fontFamily: Gilroy,
    lineHeight: "120%",
    fontSize: "calc(10px + 0.8vh)",
  },
};

export default App;
