import { Typography } from "@mui/material";
import { Colors } from "../../style";
import { Gilroy } from "../../style/fonts";

/* eslint-disable import/no-anonymous-default-export */
export default {
  actions: {
    joinUs: "Join us",
    playNow: "Play now",
  },
  labels: {
    cguv: "CGUV",
    contactUs: "Contact us",
    legal: "Legal",
    privacy: "Privacy",
    rolePlayingGames: "Role Playing Games",
    socials: "Our socials",
    theNew: "The New",
    company: "Périple Studio",
    aboutUs: "About us",
    copyright: "Copyright © ",
    terms: "Terms",
    contact: "Contact",
    supportedBy: "Project supported by",
  },
  messages: {
    availableOn: "Also available on smartphones",
    appNotInstalled:
      "It seems that the Tales Up application is not installed on your device. Click on the store button corresponding to your device (App Store or Google Play Store) to install it",
  },
  urls: {
    apple: "https://apps.apple.com/en/app/tales-up/id1533630240",
    beta: "https://beta.talesup.io",
    cguv: "https://talesup.io/cguv/en",
    contactUs: "mailto:contact@talesup.io",
    discord: "https://discord.gg/yeJ7UsWGY4",
    google:
      "https://play.google.com/store/apps/details?id=com.talesupreader&hl=en",
    legal: "https://talesup.io/legal/en",
    play: "https://play.talesup.io",
    app: "talesup://",
    privacy: "https://talesup.io/politique/en",
    socials: "https://linktr.ee/talesup",
    periple: "https://periplestudio.com",
  },
  carousel: {
    title: "Game features",
  },
  faq: {
    title: "Frequently asked questions",
    articles: [
      {
        id: "panel1",
        title: "What is Tales Up?",
        isTextFunction: true,
        text: () => (
          <Typography
            className={"articleText"}
            gutterBottom
            sx={{
              maxWidth: { sm: "100%", md: "70%" },
              color: Colors.OLD_PRICE,
              fontFamily: Gilroy,
              "&.articleText > b": {
                color: Colors.WHITE,
              },
            }}
          >
            <b> Become the heroes and heroines of history!</b>
            <br />
            In Tales Up, you are the masters of your own destiny. You decide
            decide which path to take, whether to trust the beggar woman beggar
            woman who claims to see the future, or which of you should sacrifice
            your lives if things get out of hand...
            <br />
            <br />
            <b>Discover unique adventures</b>
            <br />
            Every adventure is unique! Looking for a little conflict? Choose a
            Survival story! In the mood for magic? Choose a Fantasy story! Each
            story contains unique images, music and animations... And for those
            who prefer longer adventures, there are also series!
            <br />
            <br />
            <b>Play as you like</b>
            <br />
            It's up to you all the way, including the game mode.
            <br />
            <b>• Local mode :</b> share the experience in person with friends
            and family, in a bar with friends, at a family meal, with
            colleagues...
            <br />
            <b>• Online mode :</b> have adventures with the people of your
            choice around the world.
            <br />
            <b>• Single-player mode :</b> control your team of adventurers.
            <br />
            <br />
            <b>Progression & Collection</b>
            <br />
            As your adventures unfold, you'll be able to enrich your library
            with numerous stories, as well as customize your avatar with your
            various finds. For the more inquisitive, you can also go in search
            of the various objects and titles by exploring the hidden parts of
            the stories and completing missions!
            <br />
            <br />
            <b>Regular releases</b>
            <br />
            New content is released regularly, whether it's stories, items or
            characters.
            <br />
            <br />
            Tales Up is a cooperative role-playing game that's a cross between a
            board game and interactive stories such as "books where you're the
            hero". It's also very easy to pick up, making it ideal for an
            aperitif!
            <br />
            You'll find zombie, pirate and Viking stories... funny situations
            and surprises guaranteed!
            <br />
            Tales Up is an interactive story platform.
            <br />
            <br />
            <i>
              Note: An Internet connection is required to enjoy the game to the
              full. The game is also available as a browser game.
            </i>
            <br />
            <br />
            We're a French independent studio based in Clermont-Ferrand with a
            team of enthusiasts, and we hope you have a great time!
          </Typography>
        ),
      },
      {
        id: "panel2",
        title: "Is Tales Up a paying game?",
        isTextFunction: false,
        text: "On Tales Up, you have free access to the first episodes of all our series. Thereafter, you can purchase series and stories individually, or opt for our famous Adventurer's Pass. The latter gives you access to all our content",
      },
      {
        id: "panel3",
        title: "What are the advantages of the Adventurer's Pass?",
        isTextFunction: false,
        text: "Our Adventurer's Pass gives you access to all our content, including all our stories and series, at a very attractive price. Indeed, if you bought all our series and stories individually, it would cost you a total of 80 euros. However, with our Pass at 19.99 euros, you save around 60 euros!",
      },
      {
        id: "panel4",
        title: "What are tickets for?",
        isTextFunction: false,
        text: "Tickets are the virtual currency used in the game. With these tickets, you can buy single stories and series, avatars, as well as cosmetics to personalize your avatars and those of your companions. It's not possible to earn tickets; the only way to get them is to buy them with real money.",
      },
      {
        id: "panel5",
        title: "How to play single-player mode on Tales Up?",
        isTextFunction: false,
        text: 'On Tales Up, playing in single-player mode is perfectly possible. When you select the story or episode of your choice, choose the "Solo/Local" mode. Then click on add" and you\'ll be able to include fictional companions to accompany you. You\'ll then be responsible for making decisions for your entire team. Now all you have to do is get started!',
      },
      {
        id: "panel6",
        title: "How to play multiplayer on Tales Up?",
        isTextFunction: true,
        text: () => (
          <Typography
            className={"articleText"}
            gutterBottom
            sx={{
              maxWidth: { sm: "100%", md: "70%" },
              color: Colors.OLD_PRICE,
              fontFamily: Gilroy,
              "&.articleText > b": {
                color: Colors.WHITE,
              },
            }}
          >
            <b>To play with your friends around a table:</b>
            <br />
            When you select the story or episode of your choice, choose the
            "Solo/Local" mode. Then click on "Add" and you'll be able to enter
            the first names of all the people who will be joining you. All
            that's left for you to do is get started!
            <br />
            <br />
            <b>
              To play with your friends or other players all over the world :
            </b>
            <br />
            When you select the story or episode of your choice, choose the
            "Online" mode. You can then invite your friends using the code
            assigned to you, but you can also choose to make your game public,
            by ticking the button at the very top, allowing anyone to join you.
            <br />
            <br />
            You can also join a public game by going directly to the the
            "social" tab and play with a group of adventurers!
            <br />
            <br />
            For the more bilingual among you, you can go to the application
            settings and select the French language to find French-speaking
            players to play with.
          </Typography>
        ),
      },
      {
        id: "panel7",
        title: "In which languages is Tales Up available?",
        isTextFunction: false,
        text: "Our 21 stories and series are available in French and 20 in English!",
      },
    ],
  },
};
