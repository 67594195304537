import { useRef, useState } from "react";
import {
  columnCenterStyles,
  columnStartStyles,
  rowCenterStyles,
} from "../../style/flex";
import { Box, ButtonBase, Container, Typography } from "@mui/material";

//icons
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Colors } from "../../style";
import { getLanguage, strings } from "../../services/translation";
import { Gilroy } from "../../style/fonts";

interface Props {
  cardWidth?: number | undefined;
  cardSpacing?: number | undefined; // marge entre les cartes en pixels
  style?: any;
}

const Carousel = ({ cardWidth = 400, cardSpacing = 0, style }: Props) => {
  /**
   *
   * STATES & LOCAL VARS
   *
   */
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const cards = getLanguage() === "fr" ? images : imagesEn;
  const swipeDistanceBase = 5;

  /**
   *
   * USE EFFECTS
   *
   */

  /**
   *
   * FUNCTIONS
   *
   */

  // 2560 x 1440
  // = w x 0.5625

  /**
   * @handlePrev
   * Go to prev index
   */
  const handlePrev = () => {
    const newIndex = activeIndex === 0 ? cards.length - 1 : activeIndex - 1;
    handleScrollToIndex(newIndex);
  };

  /**
   * @handleNext
   * Go to next index
   */
  const handleNext = () => {
    const newIndex = activeIndex === cards.length - 1 ? 0 : activeIndex + 1;
    handleScrollToIndex(newIndex);
  };

  /**
   * @handleScrollToIndex
   * Scroll to specified index
   * @param index
   */
  const handleScrollToIndex = (index: number) => {
    setActiveIndex(index);
    const scrollLeft = index * (cardWidth + cardSpacing);
    carouselRef.current?.scrollTo({ left: scrollLeft, behavior: "smooth" });
  };

  let touchStartX: number | null = null;

  const handleTouchStart = (e: any) => {
    // Enregistrer la position X initiale du toucher
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchMove = (e: any) => {
    if (!touchStartX) {
      return; // Si la position initiale du toucher n'a pas été enregistrée, sortir de la fonction
    }

    // Calculer la distance de glissement par rapport à la position initiale
    const touchEndX = e.touches[0].clientX;
    const swipeDistance = touchEndX - touchStartX;

    // Réinitialiser la position initiale du toucher pour la prochaine séquence de toucher
    touchStartX = null;

    // Calculer la direction du glissement
    if (swipeDistance > swipeDistanceBase) {
      handlePrev(); // Si le glissement est vers la droite, appeler la fonction handlePrev
    } else if (swipeDistance < -swipeDistanceBase) {
      handleNext(); // Si le glissement est vers la gauche, appeler la fonction handleNext
    }
  };

  const handleTouchEnd = () => {
    // Si la position initiale du toucher n'a pas été enregistrée, sortir de la fonction
    if (!touchStartX) {
      return;
    }

    // Réinitialiser la position initiale du toucher
    touchStartX = null;
  };

  return (
    <Container id="carousel" sx={styles.base}>
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={styles.header}
      >
        {strings.carousel.title}
      </Typography>
      <Box sx={[styles.mainContainer, style ? style : {}]}>
        <ButtonBase onClick={handlePrev} disableRipple sx={[styles.prev]}>
          <NavigateBeforeIcon sx={{ fontSize: "2.3rem" }} />
        </ButtonBase>
        {/*
         ********* CAROUSEL START *********
         */}
        <Box sx={styles.container}>
          <Box
            position="relative"
            width={cardWidth}
            sx={styles.carouselOuterContainer}
          >
            <Box sx={styles.carouselContainer}>
              <Box
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                ref={carouselRef}
                sx={[
                  styles.carousel,
                  {
                    transform: `translateX(-${
                      activeIndex * (cardWidth + cardSpacing)
                    }px)`,
                  },
                ]}
              >
                {cards.map((card: any, index: number) => {
                  return (
                    <img
                      key={`card-${index}`}
                      src={card.image}
                      width={`${cardWidth}px`}
                      height={"auto"}
                      alt={card.alt}
                      style={{ marginRight: `${cardSpacing}px` }}
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
        {/*
         ********* CAROUSEL END *********
         */}

        <ButtonBase
          onClick={handleNext}
          disableRipple
          sx={[
            styles.next,
            {
              "&:hover nextIconCarousel": {
                fontSize: "2.5rem",
                color: "red",
              },
            },
          ]}
        >
          <NavigateNextIcon
            className="nextIconCarousel"
            sx={[{ fontSize: "2.3rem" }]}
          />
        </ButtonBase>
      </Box>
      {cards.length && cards.length > 1 ? (
        <Box
          sx={{
            zIndex: 3,
            width: "100%",
            marginTop: "16px",
            ...rowCenterStyles,
          }}
        >
          {cards.map((card: any, index: number) => (
            <Box
              key={"dot-" + index}
              sx={{
                ...columnCenterStyles,
                width: activeIndex === index ? "10px" : "8px",
                height: activeIndex === index ? "10px" : "8px",
                borderRadius: "50%",
                backgroundColor:
                  activeIndex === index ? Colors.WHITE : Colors.OLD_PRICE,
                marginX: "6px",
                cursor: "pointer",
              }}
              onClick={() => handleScrollToIndex(index)}
            />
          ))}
        </Box>
      ) : null}
    </Container>
  );
};

const styles = {
  base: {
    pt: { xs: 4, sm: 4 },
    pb: { xs: 4, sm: 4 },
    position: "relative",
    gap: { xs: 3, sm: 6 },
    ...columnStartStyles,
  },
  header: {
    color: Colors.WHITE,
    fontFamily: Gilroy,
    width: { sm: "100%", md: "60%" },
    textAlign: { sm: "left", md: "center" },
  },
  mainContainer: {
    ...columnCenterStyles,
    width: "100%",
    position: "relative",
  },
  container: {
    ...rowCenterStyles,
    width: "100%",
    height: "100%",
  },
  carouselOuterContainer: { margin: "0 0" },
  carouselContainer: {
    width: "100%",
    overflow: "hidden",
    borderRadius: "13px",
  },
  carousel: {
    display: "flex",
    transition: "transform 0.5s ease-in-out",
    borderRadius: "13px",
  },
  prev: {
    width: "5%",
    position: "absolute",
    left: 0,
    height: "100%",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    zIndex: 2,
    color: "#fff",
  },
  next: {
    width: "5%",
    position: "absolute",
    right: 0,
    height: "100%",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    zIndex: 2,
    color: "#fff",
  },
};

const imagesEn = [
  {
    image: require("../../assets/images/carousel/1en.jpg"),
    alt: "make the decisions",
  },
  {
    image: require("../../assets/images/carousel/2en.jpg"),
    alt: "Beware the consequences",
  },
  {
    image: require("../../assets/images/carousel/3en.jpg"),
    alt: "Immersive artwork and music",
  },
  {
    image: require("../../assets/images/carousel/4en.jpg"),
    alt: "Play with firends or single-player",
  },
  {
    image: require("../../assets/images/carousel/5en.jpg"),
    alt: "Meet other players and move up the rankings",
  },
  {
    image: require("../../assets/images/carousel/6en.jpg"),
    alt: "Level up and customize your avatar",
  },
  {
    image: require("../../assets/images/carousel/7en.jpg"),
    alt: "20+ adventures already available with over 30000 possible choices",
  },
  {
    image: require("../../assets/images/carousel/8en.jpg"),
    alt: "Forge your destinies, Pégase for Best Mobile Game 2023",
  },
];

const images = [
  {
    image: require("../../assets/images/carousel/1.jpg"),
    alt: "Prends les décisions",
  },
  {
    image: require("../../assets/images/carousel/2.jpg"),

    alt: "Méfie-toi des conséquences",
  },
  {
    image: require("../../assets/images/carousel/3.jpg"),
    alt: "Images et musiques immersives",
  },
  {
    image: require("../../assets/images/carousel/4.jpg"),
    alt: "Joue à plusieurs ou en solo",
  },
  {
    image: require("../../assets/images/carousel/5.jpg"),

    alt: "Joue avec d'autres joueurs et monte dans le classement",
  },
  {
    image: require("../../assets/images/carousel/6.jpg"),
    alt: "Monte de niveau et personnalise ton avatar",
  },
  {
    image: require("../../assets/images/carousel/7.jpg"),
    alt: "+20 aventures déjà disponibles avec +30000 choix possibles",
  },
  {
    image: require("../../assets/images/carousel/8.jpg"),
    alt: "Forge tes desitinées, Pégases 2023 meilleur jeu vidéo mobile",
  },
];

export default Carousel;
