//import LocalizedStrings from 'react-native-localization';
import LocalizedStrings from "react-localization";
import english from "./en";
import french from "./fr";

export const strings = new LocalizedStrings({
  fr: french,
  en: english,
});

export const changeLanguage = async (
  languageKey: any,
  shouldUpdateUser = false
) => {
  strings.setLanguage(languageKey);
  localStorage.setItem("language", languageKey);
  return true;
};

export const getLanguage = () => {
  return strings.getLanguage();
};
