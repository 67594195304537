import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { Colors } from "../../../style";
import { Gilroy } from "../../../style/fonts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArticleData from "../../../models/ArticleData";

export const Article = ({
  article,
  index,
  expanded,
  handleChange,
  length,
}: {
  article: ArticleData;
  index: number;
  expanded: string | boolean;
  handleChange: any;
  length: number;
}) => {
  return (
    <Accordion
      expanded={expanded === article.id}
      onChange={handleChange(article.id)}
      sx={[
        styles.accordion,
        expanded === article.id && {
          borderRadius: "13px",
        },
        index === 0 && {
          borderTopLeftRadius: "13px !important",
          borderTopRightRadius: "13px !important",
        },
        index === length - 1 && {
          borderBottomLeftRadius: "13px !important",
          borderBottomRightRadius: "13px !important",
        },
      ]}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${article.id}d-content`}
        id={`${article.id}d-header`}
        sx={{ minHeight: "67px" }}
      >
        <Typography component="h3" sx={styles.title}>
          {article.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component={"span"} gutterBottom sx={styles.text}>
          {article.isTextFunction ? article.text() : article.text}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const styles = {
  accordion: {
    backgroundColor: Colors.BTN_ACTIVE,

    ".MuiAccordionSummary-expandIconWrapper": {
      color: Colors.OLD_PRICE,
    },
  },
  title: {
    color: Colors.WHITE,
    fontFamily: Gilroy,
    fontSize: "18px",
  },
  text: {
    maxWidth: { sm: "100%", md: "70%" },
    color: Colors.OLD_PRICE,
    fontFamily: Gilroy,
  },
};
