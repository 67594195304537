import { Typography } from "@mui/material";
import { Colors } from "../../style";
import { Gilroy } from "../../style/fonts";

/* eslint-disable import/no-anonymous-default-export */
export default {
  actions: {
    joinUs: "Rejoins-nous bg",
    playNow: "Jouer maintenant",
  },
  labels: {
    cguv: "CGUV",
    contactUs: "Nous contacter",
    legal: "Mentions légales",
    privacy: "Confidentialité",
    rolePlayingGames: "Jeux De Rôle",
    socials: "Nos réseaux",
    theNew: "Les Nouveaux",
    company: "Périple Studio",
    aboutUs: "À propos",
    copyright: "Copyright © ",
    terms: "CGUV",
    contact: "Contact",
    supportedBy: "Projet soutenu par",
  },
  messages: {
    availableOn: "Disponible aussi sur smartphone",
    appNotInstalled:
      "Il semblerait que l'application Tales Up ne soit pas installée sur ton appareil. Clique sur le bouton de store qui correspond à ton appareil (App Store ou Google Play Store) pour l'installer",
  },
  urls: {
    apple: "https://apps.apple.com/fr/app/tales-up/id1533630240",
    beta: "https://beta.talesup.io",
    cguv: "https://talesup.io/cguv/fr",
    contactUs: "mailto:contact@talesup.io",
    discord: "https://discord.gg/3NPJ7mHdS8",
    google:
      "https://play.google.com/store/apps/details?id=com.talesupreader&hl=fr",
    legal: "https://talesup.io/legal/fr",
    play: "https://play.talesup.io",
    app: "talesup://",
    privacy: "https://talesup.io/politique/fr",
    socials: "https://linktr.ee/talesup",
    periple: "https://periplestudio.com",
  },
  carousel: {
    title: "Caractéristiques du jeu",
  },
  faq: {
    title: "Questions fréquemment posées",
    articles: [
      {
        id: "panel1",
        title: "Tales Up, c’est quoi ?",
        isTextFunction: true,
        text: () => (
          <Typography
            component={"span"}
            className={"articleText"}
            gutterBottom
            sx={{
              maxWidth: { sm: "100%", md: "70%" },
              color: Colors.OLD_PRICE,
              fontFamily: Gilroy,
              "&.articleText > b": {
                color: Colors.WHITE,
              },
            }}
          >
            <b>Devenez les héros / héroïnes de l'histoire !</b>
            <br />
            Dans Tales Up,vous êtes maîtres de votre destin. C'est vous qui
            décidez du chemin à prendre, de faire confiance ou non à cette
            mendiante qui prétend voir l'avenir ou encore de qui parmi vous
            devra se sacrifier si la situation dégénère…
            <br />
            <br />
            <b>Découvrez des aventures uniques</b>
            <br />
            Chaque aventure est unique ! Envie d'un peu de conflit ? Choisissez
            une histoire de Survie ! Envie de magie ? Choisissez une histoire
            Fantastique ! Chaque histoire contient des images, des musiques et
            des animations uniques… Et pour celles et ceux qui préfèrent les
            aventures plus longues, il y a aussi des séries !
            <br />
            <br />
            <b>Jouez comme vous le voulez</b>
            <br />
            C'est vous qui décidez jusqu'au bout, cela inclut le mode de jeu.
            <br />
            <b>• Mode plateau :</b> partagez l’expérience en physique avec vos
            proches ; dans un bar avec des amis, à un repas de famille, avec vos
            collègues…
            <br />
            <b>• Mode en ligne :</b> vivez des aventures avec avec les personnes
            de votre choix dans le monde entier.
            <br />
            <b>• Mode solo :</b> contrôlez votre équipe d'aventuriers.
            <br />
            <br />
            <b>Progression & Collection</b>
            <br />
            Au fil de vos aventures, vous pourrez enrichir votre bibliothèque de
            nombreuses histoires mais aussi customiser votre avatar avec vos
            différentes trouvailles. Pour les plus curieux, vous pourrez aussi
            partir à la recherche des différents objets et des différents titres
            en explorant les parties cachées des histoires et en complétant des
            missions !
            <br />
            <br />
            <b>Des sorties régulières</b>
            <br />
            Du nouveau contenu sort régulièrement, que ce soit des histoires,
            des objets ou des personnages.
            <br />
            <br />
            Tales Up est un jeu de rôle en coopération entre un jeu de société
            et des histoires interactives telles que les "livres dont vous êtes
            le héros". C'est aussi un jeu très facile à prendre en main donc
            idéal pour l'apéro !
            <br />
            Vous trouverez des histoires de zombie, pirate ou viking… Situations
            drôles et surprises garanties !
            <br />
            Tales Up est une plateforme d’histoires interactives.
            <br />
            <br />
            <i>
              Remarque : Une connexion Internet est nécessaire pour profiter
              pleinement du jeu. Le jeu est aussi disponible sur navigateur web.
            </i>
            <br />
            <br />
            Nous sommes un studio indépendant français basé à Clermont-Ferrand
            rassemblant une équipe de passionné·es et nous espérons que vous
            passerez un bon moment !
          </Typography>
        ),
      },
      {
        id: "panel2",
        title: "Tales Up est-il un jeu payant ?",
        isTextFunction: false,
        text: "Sur Tales Up, vous avez accès gratuitement aux premiers épisodes de toutes nos séries. Par la suite, vous avez la possibilité d'acheter des séries et des histoires à l'unité, ou d'opter pour notre fameux Pass Aventurier. Ce dernier vous donne accès à l'intégralité de notre contenu.",
      },
      {
        id: "panel3",
        title: "Quels sont les avantages du pass aventurier ?",
        isTextFunction: false,
        text: "Notre Pass Aventurier te donne accès à l'ensemble de notre contenu, comprenant toutes nos histoires et séries, à un prix très avantageux. En effet, si tu achètes toutes nos séries et histoires à l'unité, cela te coûterait 80 euros au total. Cependant, avec notre Pass à 19,99 euros, tu économises environ 60 euros !",
      },
      {
        id: "panel4",
        title: "À quoi servent les tickets ?",
        isTextFunction: false,
        text: "Les tickets sont la monnaie virtuelle utilisée dans le jeu. Avec ces tickets, tu peux acheter des histoires et des séries à l'unité, des avatars, ainsi que des cosmétiques pour personnaliser tes avatars et ceux de tes compagnons. Il n'est pas possible de gagner des tickets ; la seule façon d'en obtenir est de les acheter avec de l'argent réel.",
      },
      {
        id: "panel5",
        title: "Comment jouer en mode solo sur Tales Up ?",
        isTextFunction: false,
        text: "Sur Tales Up, jouer en mode solo est tout à fait possible. Lorsque tu sélectionnes l'histoire ou l'épisode de ton choix, choisis le mode \"Solo/Local\". À ce moment-là, clique sur \"ajouter\" et tu pourras inclure des compagnons fictifs qui t'accompagneront. Tu seras alors responsable de prendre les décisions pour l'ensemble de ton équipe. Il ne te reste plus qu’à te lancer dans l’aventure !",
      },
      {
        id: "panel6",
        title: "Comment jouer en multi-joueur sur Tales Up ?",
        isTextFunction: true,
        text: () => (
          <Typography
            component={"span"}
            className={"articleText"}
            gutterBottom
            sx={{
              maxWidth: { sm: "100%", md: "70%" },
              color: Colors.OLD_PRICE,
              fontFamily: Gilroy,
              "&.articleText > b": {
                color: Colors.WHITE,
              },
            }}
          >
            <b>Pour jouer avec tes amis autour d’une table :</b>
            <br />
            Lorsque tu sélectionnes l'histoire ou l'épisode de ton choix,
            choisis le mode "Solo/Local". Ensuite, clique sur "ajouter" et tu
            pourras saisir le prénom de toutes les personnes qui
            t'accompagneront. Il ne te restera plus qu'à vous lancer dans
            l'aventure !
            <br />
            <br />
            <b>
              Pour jouer avec tes amis ou d'autres joueurs partout dans le monde
              :
            </b>
            <br />
            Lorsque tu sélectionnes l'histoire ou l'épisode de ton choix,
            choisis le mode “En ligne”. Tu pourras alors inviter tes amis en
            utilisant le code qui te sera attribué, mais tu peux également
            choisir de rendre ta partie publique, en cochant le bouton tout en
            haut permettant ainsi à n'importe qui de vous rejoindre.
            <br />
            <br />
            Tu peux aussi rejoindre une partie publique en accédant directement
            à l'onglet "social" et jouer avec un groupe d'aventuriers !
            <br />
            <br />
            Pour les plus bilingues d’entre vous vous avez la possibilité de
            vous rendre dans les paramètres de l'application et de sélectionner
            la langue anglaise afin de trouver des joueurs anglophones avec qui
            jouer.
          </Typography>
        ),
      },
      {
        id: "panel7",
        title: "Dans quelles langues est disponible Tales Up ?",
        isTextFunction: false,
        text: "Nos 21 histoires et séries sont disponibles en Français et 20 en Anglais !",
      },
    ],
  },
};
